




























import { Component, mixins } from 'nuxt-property-decorator';
import IconMixin from '../../mixins/IconMixin';

@Component
export default class Plus extends mixins(IconMixin) {}
